<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent" ></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent" ></component>
      </div>
      <div
        slot="bottom_box_content"
        style="padding:10px 10px;"
      >
        <component
          :is="curBottomComponent"
        ></component>
      </div>
      <div slot="bg_map"></div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
import reconciliationStoreModule from '@/store/modules/reconciliation'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    // 底部
    List: () => import('@/components/reconciliation/IndexTable'),
    Detail: () => import('@/components/reconciliation/Detail'),

    equityAmountDetail: () => import('@/components/reconciliation/EquityAmountDetail'),
    equityAdjustmentDetail: () => import('@/components/reconciliation/EquityAdjustmentDetail'),

    statementContractDetail: () => import('@/components/reconciliation/StatementContractDetail'),

    Right: () => null,
    Left: () => null
  },
  data () {
    return {
      taskId: 0,
      taskBottomtype: 1,
      showAllTask: false
    }
  },
  watch: {
    pageType (val) {
      // 清空便签项
      this.setLinkTagArray()
      if (val === 'detail') {
        this.setBottomComponent('Detail')
        this.setLinkTagArray({ key: 'reconciliationList', value: '代理商对账簿' })
        this.setLinkTagArray({ key: 'reconciliationDetail', value: '对账簿详情' })
        this.setActivedTagName('reconciliationDetail')
      } else if (val === 'equityAmountDetail') { // 权益额明细
        this.setBottomComponent('equityAmountDetail')

        this.setLinkTagArray({ key: 'reconciliationList', value: '代理商对账簿' })
        this.setLinkTagArray({ key: 'reconciliationDetail', value: '对账簿详情' })
        this.setLinkTagArray({ key: 'equityAmountDetail', value: '权益额明细' })
        this.setActivedTagName('equityAmountDetail')
      } else if (val === 'equityAdjustmentDetail') { // 权益额调整项明细
        this.setBottomComponent('equityAdjustmentDetail')

        this.setLinkTagArray({ key: 'reconciliationList', value: '代理商对账簿' })
        this.setLinkTagArray({ key: 'reconciliationDetail', value: '对账簿详情' })
        this.setLinkTagArray({ key: 'equityAdjustmentDetail', value: '权益额调整项明细' })
        this.setActivedTagName('equityAdjustmentDetail')
      } else if (val === 'statementContractDetail1') { // 权益额明细所属合同明细
        this.setBottomComponent('statementContractDetail')

        this.setLinkTagArray({ key: 'reconciliationList', value: '代理商对账簿' })
        this.setLinkTagArray({ key: 'reconciliationDetail', value: '对账簿详情' })
        this.setLinkTagArray({ key: 'equityAmountDetail', value: '权益额明细' })
        this.setLinkTagArray({ key: 'statementContractDetail', value: '合同明细' })
        this.setActivedTagName('statementContractDetail')
      } else if (val === 'statementContractDetail2') { // 权益额调整项明细所属合同明细
        this.setBottomComponent('statementContractDetail')

        this.setLinkTagArray({ key: 'reconciliationList', value: '代理商对账簿' })
        this.setLinkTagArray({ key: 'reconciliationDetail', value: '对账簿详情' })
        this.setLinkTagArray({ key: 'equityAdjustmentDetail', value: '权益额调整项明细' })
        this.setLinkTagArray({ key: 'statementContractDetail', value: '合同明细' })
        this.setActivedTagName('statementContractDetail')
      } else {
        this.setPageDefaultComponent()
      }
      this.initTagEvent()
    }
  },
  created () {
    if (!this.$store.hasModule('reconciliation')) {
      this.$store.registerModule('reconciliation', reconciliationStoreModule)
      this.$store.commit('set_reconciliation_companyId', this.$store.getters.token.userInfo.companyId)
      this.$store.commit('set_reconciliation_publisherId', this.$store.getters.token.userInfo.publisherId)
      this.$store.commit('set_reconciliation_serviceType', 2)
    }
  },
  methods: {
    initTagEvent () {
    // 注册头部事件
      this.setLinkRouterMaps(['reconciliationList', () => {
        this.$store.commit('set_reconciliation_pageType', 'list')
      }])
      this.setLinkRouterMaps(['reconciliationDetail', () => {
        this.$store.commit('set_reconciliation_pageType', 'detail')
      }])

      this.setLinkRouterMaps(['equityAmountDetail', () => {
        this.$store.commit('set_reconciliation_pageType', 'equityAmountDetail')
      }])
      this.setLinkRouterMaps(['equityAdjustmentDetail', () => {
        this.$store.commit('set_reconciliation_pageType', 'equityAdjustmentDetail')
      }])
    },
    setPageDefaultComponent () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setBottomComponent('List')
      this.setLinkTagArray({ key: 'reconciliationList', value: '代理商对账簿' })
      this.setActivedTagName('reconciliationList')
    }
  },
  computed: {
    pageType () {
      return this.$store.state.reconciliation ? this.$store.state.reconciliation.pageType : ''
    }
  },
  destroyed () {
    // 卸载reconciliation动态store模块
    this.$store.unregisterModule('reconciliation')
    this.setShowLeftBlock(false)
    this.setShowMap(false)
    this.setShowRightBlock(false)
    this.setBottomHeight('90%')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
