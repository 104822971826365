const reconciliation = {
  state: {
    pageType: 'list',
    beginUpdate: new Date(),
    publisherId: null,
    companyId: null,
    bill: null,
    serviceType: 1, // 服务对象，1：业主，2：代理商
    contractQuery: {}
  },
  mutations: {
    set_reconciliation_serviceType (state, data) {
      state.serviceType = data
    },
    set_reconciliation_pageType (state, data) {
      state.pageType = data
    },
    set_reconciliation_update (state, data) {
      state.beginUpdate = data
    },
    set_reconciliation_publisherId (state, data) {
      state.publisherId = data
    },
    set_reconciliation_companyId (state, data) {
      state.companyId = data
    },
    set_reconciliation_bill (state, data) {
      state.bill = data
    },
    set_reconciliation_contractQuery (state, data) {
      state.contractQuery = data
    }
  },
  getters: {

  },
  actions: {

  }
}

export default reconciliation
